export default {
	'translation': {
		'common': {
			'backhome': '返回主页',
			'free': '是免费的',
			'checkout': '下订单',
			'yes': '是的',
			'no': '非也。',
			'remove': '移走',
			'nodepartment': '没有部门',
			'back': '返回'
		},
		'index': {
			'kvanbanner': {
				'title': '关，滑雪胜地',
				'skipass': '滑雪通行证',
				'tubing': '油管/油管',
				'learn': '滑冰训练',
				'rent': '租金'
			},
			'oos': {
				'notworking': '目前，该对象不工作。',
				'schedule_from': '对象从{{from}}的开放时间',
				'schedule': '对象的开放时间为{{from}}到{{to}}'
			}
		},
		'itempage': {
			'addtocart': '加入购物车',
			'details': '更详细',
			'scheduleservice': '選擇',
			'scheduleservice_cancel': '取消',
			'timebooked': '已预订',
			'incart': '在購物車中',
			'selecttime': '选择时间',
			'timebooked_close': '當日預訂已完成'
		},
		'errorpage': {
			'error': '错误',
			'pagenotfound': '没有找到这样的页面:(',
			'attention': '注意！',
			'paymentfailed': '还没有付款！',
			'paymentretry': '再试一次',
			'backtoorder': '回到订单'
		},
		'checkout': {
			'yourorder': '您的订单',
			'vat': '增值税',
			'servicefee': '服务付款',
			'servicefeeinfo': '为了维持服务的运作，我们会按<b>{{amount}}</b>的金额收取服务费。 费用包括订单处理、客户支持、性能改进以及向买方交付货物或服务的成本。',
			'total': '总金额',
			'alcowarn': '酒类的付款只有在收到订单后，通过终端或现金。 <br/>交付-在设施的领土内',
			'form': {
				'howtopay': '您如何方便付款？',
				'room': '房间号码',
				'name': '姓名',
				'phone': '电话号码',
				'phoneplaceholder': '输入电话号码',
				'note': '愿望',
				'placeorder': '订购',
				'phonelengtherror': '填写电话号码',
				'pickup': '撿起'
			},
			'item': {
				'timeslot': '从{{from}}到{{to}}'
			},
			'privacypolicy': {
				'agree': '您同意',
				'withpolicy': '隐私政策和优惠'
			},
			'payment': {
				'online': '在线，通过卡',
				'cash': '现金',
				'card': '终端，通过卡'
			},
			'paymenttype': '付款',
			'noorders': '还没有订单',
			'pagetitle': '下订单',
			'options': '选项',
			'confirmdelete': '您确定要删除整个订单吗？'
		},
		'checkorder': {
			'confirmcancel': '你确定要取消订单吗?',
			'order': '秩序',
			'payment': '付款',
			'paid': '支付',
			'notpaid': '不支付',
			'changepayment': '可以更改付款方式',
			'paymenttype': '付款方法',
			'timelefttopay': '还有时间支付',
			'pay': '支付',
			'cancel': '取消'
		},
		'ordersuccess': {
			'orderaccepted': '您的订单已被接受',
			'orderno': '订单号码 # {{ orderID }}',
			'trackorder': '跟踪订单',
			'receiptlink': '链接到您的财务数据提供商的付款收据',
			'pagetitle': '秩序 #{{ orderID }}'
		},
		'bannercarousel': {
			'demohotel': {
				'freeday': '一天的逗留作为礼物',
				'romantik': '浪漫晚餐'
			}
		},
		'footer': {
			'copyright': 'STAYDAY ©️{{ year }} - 网上店面服务',
			'privacypolicy': '私隐政策及优惠',
			'notadvert': '网站上的所有材料都是信息性的，不是广告'
		},
		'cookiesmsg': {
			'datausage': '数据使用',
			'disclaimer': '使用cookie和技术数据收集服务，以确保可操作性并提高服务质量。',
			'agree': '同意。'
		},
		'foradult': {
			'is18': '你已经18岁了吗？',
			'disclaimer': '本节所载资料不建议未满法定年龄的人士使用。'
		},
		'orderstatus': {
			'statusword': '状况',
			'status': {
				'new': '新的',
				'split': '分',
				'inwork': '在工作中',
				'processing': '处理过程',
				'ready': '准备发出',
				'delivered': '交付中',
				'completed': '交付',
				'canceled': '取消',
				'confirmed': '已确认'
			},
			'servicebytime': {
				'status': {
					'new': '新的',
					'processing': '处理过程',
					'confirmed': '已确认',
					'inwork': '在工作中',
					'completed': '已完成',
					'canceled': '取消'
				}
			}
		},
		'textpage': {
			'abouthotel': '关于酒店',
			'texts': {
				'22': `
          <p>SK Royal Hotel Kaluga (ex. Kwan) – 乡村酒店距离卡卢加市中心有10分钟车程，距离莫斯科有2小时车程。</p>

          <p>该建筑本身是一个独特的建筑结构，因为在冬季，Kvan滑雪综合体的主要下降始于屋顶。 周围有美丽如画的奥卡三角洲景色.</p>

          <p>SK Royal Калуга (ex. Kwan) 酒店为客人提供舒适的住宿和成功的商务服务:</p>

          <p>主楼有74间客房，7间平房和2间客房；各处均提供免费无线网络连接；数间
            可容纳300位客人的宴会场地、大堂酒吧、冬季咖啡厅和夏季阳台；会议室
            配备现代化设备;300个地方有人看守的停车场。</p>

          <p>两间带俄罗斯浴室的浴室位于燃木炉上，最多可容纳8位客人，舒适地坐落在树木繁茂的地区。酒店还设有带健身房、桑拿浴室、土耳其浴室和游泳池的spa中心。</p>
        `,
				'68': `
          <p>DemoHotel酒店是一家位于市中心的五星级酒店。 这是任何形式的娱乐的理想解决方案：主要景点，娱乐和商务中心都位于步行距离内，以及令人惊叹的步行场所</p>

          <p>我们的酒店提供优质的住宿和各种类型的客房，以现代风格装饰，精致的装饰和一流的设施。 每个房间都有一个选择
            24小时服务，以确保我们的客人的舒适和满意度。</p>

          <p>我们为我们的高级餐厅感到自豪，他们的厨师将满足任何美食品味，无论您喜欢经典美食还是异国情调的菜肴。 我们的工作人员将很乐意为您提供最好的葡萄酒和饮料，让您充分享受您的住宿。 我们的主餐厅每天早晨供应种类繁多的自助早餐。</p>

          <p>您可以在SPA中心放松身心，在那里您将获得各种护理服务，以获得完全放松和能量恢复。 客人可以享受按摩服务、芬兰桑拿浴室或土耳其浴室。
              我们友好和专业的员工随时准备照顾您的福祉。</p>

          <p>对于那些希望在入住期间保持健康的客人，我们的健身中心将为您提供一流的健身器材和高质量的个人训练。 我们有你需要的一切，让你保持良好的状态，即使在旅行。</p>

          <p>我们很荣幸为客人提供高标准的服务。 我们的员工为每位客人提供个性化的服务，随时准备满足他们的任何愿望或需求。 感谢我们周到的服务，您在我们这里的逗留将是难忘的。</p>
        `,
				'77': `
          <div class="mb-4">
            <p class="fw-bold m-0">有哪些类型的订单可供选择</p>
            <span>您可以通过二维码订购食物和饮料，服务员会将您的订单带到餐桌上或在餐厅柜台自己点菜。</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0"><我想一次从几个角落订购。 有可能吗？/p>
            <span>是的！ 您可以在同一时间从任何角落进行组合订单。 将所需的菜肴添加到购物车并在线支付。</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">我在下订单时遇到了麻烦。 我应该在哪里联系？</p>
            <span>请致电+79308482525与送货经理联络</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">如何订购含酒精的饮料？</p>
            <span>在这里，您可以熟悉角落的酒精地图"酒吧，葡萄酒和牡蛎"，"Bebeer"。 请注意，酒吧供应酒精饮料。</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">服务费</p>
            <span>自动加收订单金额10%的服务费。</span>
          </div>
        `,
				'82': `
          <p>伏尔加格勒的"Yuzhny"酒店位于伏尔加格勒的商业中心，位于历史街区。 这座城市的历史和现代酒店建筑的独特结合体现在内部设计中。</p>

          <p>从酒店步行可达的地方有：一个美丽的公园，伏罗希洛夫购物中心，一个购物画廊，电影院Kinomax和五星级。</p>

          <p>190间客房，装饰着品味和爱，给了三星级酒店的一切理由，声称在伏尔加格勒的客人一个受欢迎的城市酒店的地位。</p>
        `,
				'empty': '正在填写有关对象的信息'
			}
		}
	}
}
